import {API_BASE_URL} from "./config";
import CryptoJS from "crypto-js";
import PhoneNumber from 'awesome-phonenumber';
import moment from "moment";
import { now } from "lodash";

export default {
    data() {
        return {
            debounceTimer: null,
            entityTypeSelectItems: [
                {id: 0, value: "private", text: ""},
                {id: 1, value: "company", text: ""},
            ],
            statusFilters: {
                estimation: ["open", "waiting", "closed"],
                purchase_order: ["open", "waiting","closed", "canceled"],
                bill: ["waiting", "partial_payed","payed", "canceled"],
                credit_note: ["waiting", "partial_payed", "payed", "canceled"],
            },
            listingStatusFilters: {
                estimation: ["open", "waiting", "closed"],
                purchase_order: ["open", "waiting", "closed", "canceled"],
                bill: ["open", "waiting", "partial_payed", "payed", "canceled"],
                credit_note: ["open", "waiting", "partial_payed", "payed", "canceled"],
            },
            cancelledStatusFilters: ["canceled", "closed"],
            creditNoteMultiplicationDefaultValue: {
                value: -1,
            },
            availableLocales: []
        };
    },
    computed: {
        availableResources(){
            let data = JSON.parse(localStorage.getItem("data"));
            if(data){
                let applicationResources = [];
                data.applicationResources.forEach((resource) => {
                    if (resource.application_resource.key) {
                        resource.application_resource.text = this.$t('attachments_module.application_resources.' +resource.application_resource.key);
                        applicationResources.push(resource.application_resource);
                    }
                });
                return applicationResources;
            }
            return [];
        },
        attachmentResources(){
            if(this.availableResources && this.availableResources.length > 0){
                let attachmentResources = ['billing', 'vehicles', 'client'];
                return this.availableResources.filter(element => {
                    if(attachmentResources.includes(element.key)){
                        return element;
                    }
                    return false;
                })
            }
            return [];
        },
        userRole(){
            return JSON.parse(localStorage.getItem("data")).userDetails.role;
        },
        currentTimeZone(){
            return moment.tz.guess() ?? 'Europe/Paris';
        },
        daysOfWeek() {
            return [
                this.$t("days.sunday"),
                this.$t("days.monday"),
                this.$t("days.tuesday"),
                this.$t("days.wednesday"),
                this.$t("days.thursday"),
                this.$t("days.friday"),
                this.$t("days.saturday"),
            ];
        },
        getDueDate() {
            let defaultDueDays = this.getCompanyPropertyFromLocalStorage('DEFAULT_DOCUMENT_DUE_DAYS') ? this.getCompanyPropertyFromLocalStorage('DEFAULT_DOCUMENT_DUE_DAYS').value : 30;
            //
            let newDueDate = new Date(new Date().getTime() + ((defaultDueDays) * 24 * 60 * 60 * 1000));

            console.log(defaultDueDays, newDueDate, "new due date");
            return newDueDate.toISOString().substr(0, 10);
        },
        currentDate() {
            let newDate = new Date(new Date().getTime());
            return newDate.toISOString().substr(0, 10);
        },
    },
    beforeMount() {
        this.getLocales();
    },
    mounted() {
        this.entityTypeSelectItems = this.translateKeys(
            this.entityTypeSelectItems,
            "value",
            "entity_type"
        );
    },
    methods: {
        checkIfReclamationNeedToShowForMainClient(client){
            if(client && typeof client == 'object' && client.is_leaser == 0){ // Do not show reclamation for leaser like "ALD AUTOMOTIVE"
                return true
            }
            return false;
        },
        checkIfReclamationNeedToShow(client){
            if(client && typeof client == 'object'){ // Show only if it is an object
                return true
            }
            return false;
        },
        disableOrderOrExpenseStatus(document){
            if(document && document.status_id && document.status_id == 10251003 && !this.isAdmin){
                return true;
            }
            return false;
        },
        makeSingleLineText(text){
            let regex = /(<([^>]+)>)/ig;
            return (text ?? "").replace(regex, "");
        },
        downloadCSVFromJson(filename, csvHeaders, arrayOfJson){
            // convert JSON to CSV
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            const header = csvHeaders
            console.log("Header",header, csvHeaders);
            let csv = arrayOfJson.map(row => header.map(fieldName => 
            JSON.stringify(row[fieldName], replacer)).join(';'))
            csv.unshift(header.join(';'))
            csv = csv.join('\r\n')

            // Create link and download
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        convertNumberToInternationalFormat(numberValue){
            if(!numberValue){
                numberValue = 0;
            }
            let localeSetting = this.getCompanyPropertyFromLocalStorage('LOCALE_SETTING') ? this.getCompanyPropertyFromLocalStorage('LOCALE_SETTING').value : null;
            if(localeSetting){
                return new Intl.NumberFormat(localeSetting, {
                    useGrouping: false,
                    minimumFractionDigits: this.getDecimalNumber(),
                    maximumFractionDigits: this.getDecimalNumber()
                }).format(numberValue);                    
            }
            return new Intl.NumberFormat('en-US', {
                useGrouping: false,
                minimumFractionDigits: this.getDecimalNumber(),
                maximumFractionDigits: this.getDecimalNumber()
            }).format(numberValue);
        },
        showFormattedNumber(number){
            return this.convertNumberToInternationalFormat(number);
            // if(number){
            //     number = typeof number == 'string' ? number : number.toFixed(this.getDecimalNumber());
            //     return this.convertNumberToInternationalFormat(number); 
            // }
            // return this.convertNumberToInternationalFormat((0).toFixed(this.getDecimalNumber()));
        },
        customParseFloat(value){
            if(typeof value == 'string'){
                return parseFloat(value.replace(',', ''));
            }
            else{
                return parseFloat(value);
            }
        },
        resetPDFViewerProperties(){
            this.$store.commit("setPreviewUrl", null);
            this.$store.commit("setPreviewMimeType", null)
            this.$store.commit("setIsShowFullScreen", true)
            this.$store.commit("setOpenInNewWindow", false)
        },
        getModuleId(toSearchResource){
            let module_id = null;
            let data = JSON.parse(localStorage.getItem("data"));
            let applicationResourceId = null;
            data.applicationResources.forEach((resource) => {
                if (resource.application_resource.key == toSearchResource) {
                    applicationResourceId = resource.application_resource.id;
                }
            });
            data.attachmentModules.forEach((modules) => {
                if (modules.application_resource_id == applicationResourceId) {
                    module_id = modules.id;
                }
            });
            return module_id;
        },
        calculateNoOfDaysLeftInDueDate(dueDate){
            if(dueDate){
                dueDate = moment(moment(dueDate).format('YYYY-MM-DD'));
                let todayDate = moment(moment().format('YYYY-MM-DD'));
                return dueDate.diff(todayDate, 'days');
            }
            return 0;
        },
        checkIfItemIsBillOrCreditNote(key){
            if(key == 'bill' || key == 'credit_note'){
                return true;
            }
            return false;
        },
        checkIfItemIsPurchaseOrderOrBillOrCreditNote(key){
            if(key == 'purchase_order' || key == 'bill' || key == 'credit_note'){
                return true;
            }
            return false;
        },
        checkIfItemIsClosedOrCancelled(key){
            if(key == 'canceled' || key == 'closed'){
                return true;
            }
            return false;
        },
        getColorOnTheBasisOfPayedValue(total, totalPayed){
            if(parseFloat(total) != 0){
                if(parseFloat(totalPayed) == parseFloat(total)){
                    return 'green';
                }
                else if(parseFloat(totalPayed) == 0 && parseFloat(total)){
                    return 'red';
                }
                else if(parseFloat(totalPayed) != 0 && parseFloat(totalPayed) < parseFloat(total)){
                    return 'orange';
                }
            }
            return 'red';
        },
        getColorOnTheBasisOfStatus(item, total, totalPayed, itemStatus){
            if(itemStatus == 'payed'){
                return '#006d00';
            }
            if(itemStatus == 'partial_payed'){
                return 'green';
            }
            if(itemStatus == 'waiting'){
                if(this.calculateNoOfDaysLeftInDueDate(item.due_date) < 0){
                    return 'red';
                }
                return 'orange';
            }
            if(itemStatus == 'open'){
                return 'blue';
            }
            if(itemStatus == 'approved'){
                return 'skyblue'
            }
            return 'grey';
        },
        getHeaderStyle(header) {
            // Implement your logic to calculate the header style
            // based on the header object (e.g., width and alignment)
            // Return an object with CSS properties.
            let headerWidthCss = this.getHeaderWidth(header, header.width, true);
            console.log("header_width", headerWidthCss);
            return {
                width: headerWidthCss,
                textAlign: (header.align || 'start') + '!important', // Default to 'start'
            };
        },
        getHeaderWidth(header, width, returnValue = false){
            if(width){
                width = width + '';
                    if(width.includes('px') || width.includes('%')){
                    return returnValue ? (width) : ('width: ' + width + ';' + 'max-width: ' + width );
                }
                else{
                    return returnValue ? (width + '%') : ('width: ' + width + '%;');
                }
            }
            return returnValue ? '150px' : 'width: 150px';
        },
        getPayedStatus(total, totalPayed, itemStatus){
                return this.$t("billings.statuses." + itemStatus);
        },
        generateDueDate(currentDate, clientPaymentTermDays){ 
            // PAYMENT_TERMS is same as DEFAULT_DOCUMENT_DUE_DAYS, if it is present then it should be calculated on that basis otherwise it will be calculated on the basis of DEFAULT_DOCUMENT_DUE_DAYS
            let defaultDueDays = clientPaymentTermDays ? clientPaymentTermDays : (this.getCompanyPropertyFromLocalStorage('DEFAULT_DOCUMENT_DUE_DAYS') ? this.getCompanyPropertyFromLocalStorage('DEFAULT_DOCUMENT_DUE_DAYS').value : 30);
            //
            console.log(currentDate, "-------", currentDate ? new Date(currentDate) : new Date());
            let newDueDate = new Date((currentDate ? new Date(currentDate) : new Date()).getTime() + ((defaultDueDays) * 24 * 60 * 60 * 1000));

            console.log(defaultDueDays, newDueDate.toISOString().substr(0, 10), "new due date_____");
            return newDueDate.toISOString().substr(0, 10);
        },
        checkIfAddressEmpty(address){
            if(! address){
                return true;
            }
            else if(address && typeof address == 'object' && address?.street == null && address?.building_id == null && address?.place_id ==null && address?.zip ==null && address?.country ==null && address?.country_code ==null && address?.country_id ==null && address?.county ==null && address?.state ==null && address?.region ==null && address?.city ==null && address?.lat ==null && address?.long ==null && address?.provider ==null && address?.formatted ==null && address?.name ==null && address?.location_name ==null){
                return true;
            }
            return false;
        },
        addSpaceAfterThirdChar(str) {
            var result = "";
            for (var i = 0; i < str.length; i++) {
                result += str.charAt(i);
                if ((i + 1) % 3 === 0 && i !== str.length - 1) {
                    result += " ";
                }
            }
            return result;
        },
        findCountry(iso = '') {
            return this.preferredCountry.find(
                country => country === iso.toUpperCase(),
            );
        },
        formatToPhoneNumber(val) {
            let phonenumber = val ?? "";
            let code = PhoneNumber(phonenumber).getRegionCode();
            let countryDialCode = PhoneNumber(phonenumber).getCountryCode();
            if (code && countryDialCode) {
                return "+" + countryDialCode + " " + this.addSpaceAfterThirdChar(val.replace("+", "").replaceAll(" ", "").replace(countryDialCode, ""));
            }
            return val;
        },
        /** Swap Article array elements from one position to another */
        swap_article_array_elements(arr, old_index, new_index) {
            arr.forEach((element) => {
            });
            // let tempElement = arr[old_index];
            // arr[old_index] = arr[new_index];
            // arr[new_index] = tempElement;
            let removedItem = arr.splice(old_index, 1)[0];
            arr.splice(new_index, 0, removedItem);
            let count = 0;
            arr.forEach((element) => {
                element.srno = count++;
                element.row = count;
            });
            console.log("-----------------");
            arr.forEach((element) => {
                console.log(
                    "moving---------",
                    element.article_original_name.identifier
                );
            });
            return arr;
        },
        getLocales() {
            return new Promise((resolve) => {
                this.availableLocales = [];
                const locales = require.context(
                    "./locales",
                    true,
                    /[A-Za-z0-9-_,\s]+\.json$/i
                );
                locales.keys().forEach((key) => {
                    const locale = key
                        .replaceAll("/", "")
                        .replaceAll(".json", "")
                        .replaceAll(".", "");
                    this.availableLocales.push(locale);
                });
                resolve();
            });
        },
        parseSymbol(symbolCode) {
            const htmlDom = new DOMParser().parseFromString(symbolCode, 'text/html');
            return htmlDom.documentElement.textContent;
        },
        toLocalISOString(date) {
            console.log("date_uploaded", date);
            if(date){
                const localDate = new Date(date - date.getTimezoneOffset() * 60000); //offset in milliseconds

                // Optionally remove second/millisecond if needed
                localDate.setSeconds(null);
                localDate.setMilliseconds(null);
                return localDate.toISOString().slice(0, -1);
            }
        },
        setTopCurrencyBasedOnDefaultBank(bank_id = null) {
            if (!bank_id && this.defaultBankAccount) {
                bank_id = this.defaultBankAccount.id;
            }
            this.bankAccounts.forEach((element) => {
                if (element.id == bank_id) {
                    this.$store.commit("setBankAccount", element);
                }
            });
        },
        async findCompanyLocale(passedLocale) {
            await this.getLocales();
            let data = JSON.parse(localStorage.getItem("data"));
            let companyIdentifier = data.userDetails.company.identifier;
            let userLanguage = localStorage.getItem("languageId");
            let localLocale = passedLocale
                ? companyIdentifier + "" + passedLocale
                : companyIdentifier + "" + userLanguage;
            let localFound = this.availableLocales.find(
                (element) => {
                    return element == localLocale
                }
            );

            let passedLocaleFound = this.availableLocales.find(
                (element) => {
                    return element == passedLocale
                }
            );
            if (localFound) {
                return localLocale;
            } else if (passedLocaleFound) {
                return passedLocale;
            } else {
                return userLanguage;
            }
        },
        selectDefaultBillType(billTypesArray) {
            console.log("resetting bill Type");
            let billTypeCode = this.getCompanyPropertyFromLocalStorage('DEFAULT_BILLING_DOCUMENT');
            if (billTypeCode && billTypeCode.value) {
                let foundBillType = billTypesArray.find((element) => {
                    if (element.id == billTypeCode.value) {
                        return element;
                    }
                });
                if (foundBillType) {
                    return foundBillType;
                }
                return billTypesArray[1];
            }
            return billTypesArray[1];
        },
        selectDefaultBillTypeFromNavigationData(){
            console.log("selected bill -------", this.billTypes);
            let selectedBillType = null;
            this.billTypes.forEach((element) => {
                if(this.navigationData && this.navigationData.name && this.navigationData.name.includes("."+element.key)){
                    console.log("navigation data and bill type----",element.key + "======="+ this.navigationData.name);
                    selectedBillType = element;
                    this.billType = element;
                }
            })
            
            if(!selectedBillType){
                selectedBillType = this.selectDefaultBillType(this.billTypes);
            }
            console.log("selected bill #########", selectedBillType);
            return selectedBillType;
        },
        selectDefaultexpensesType(expensesTypesArray) {
            console.log("resetting expense Type");
            let expensesTypeCode = this.getCompanyPropertyFromLocalStorage('DEFAULT_EXPENSES_DOCUMENT');
            if (expensesTypeCode && expensesTypeCode.value) {
                let foundexpensesType = expensesTypesArray.find((element) => {
                    if (element.id == expensesTypeCode.value) {
                        return element;
                    }
                });
                if (foundexpensesType) {
                    return foundexpensesType;
                }
                return expensesTypesArray[0];
            }
            return expensesTypesArray[0];
        },
        selectDefaultExpenseTypeFromNavigationData(){
            console.log("selected Expense -------", this.expensesTypes);
            let selectedExpenseType = null;
            this.expensesTypes.forEach((element) => {
                if(this.navigationData && this.navigationData.name && this.navigationData.name.includes("."+element.key)){
                    console.log("navigation data and bill type----",element.key + "======="+ this.navigationData.name);
                    selectedExpenseType = element;
                    this.expenseType = element;
                }
            })
            
            if(!selectedExpenseType){
                selectedExpenseType = this.selectDefaultexpensesType(this.expensesTypes);
            }
            console.log("selected Expense #########", selectedExpenseType);
            return selectedExpenseType;
        },
        copyText() {
            document.getElementById("textToCopy").select();
            document.execCommand("copy");
        },
        checkTag(tag) {
            return (JSON.parse(localStorage.getItem("applicationResources")) ?? []).includes(
                tag
            ) ?? false;
        },

        checkUserTag(tag) {
            let userRole = JSON.parse(localStorage.getItem("data")).userDetails.role;

            if(userRole.name === 'admin'){
                return true;
            }


            return userRole.resources.find(r => r.application_resource.key === tag) ?? false;
        },
        getDayViewWidth() {
            if (document.getElementById("scrollableDayView")) {
                return (
                    parseInt(document.getElementById("scrollableDayView").offsetWidth) -
                    150
                );
            }
            return 250;
        },
        setTableThTdWidth(width) {
            let boxes = document.querySelectorAll(".tablethtd");
            boxes.forEach((box) => {
                box.style.minWidth = `180px`;
            });
        },
        getDay(date) {
            let i = new Date(date).getDay(date);
            return this.daysOfWeek[i];
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, "0");
        },
        getDateByXDays(n){
            return new Date(new Date().getTime() + n * 24 * 60 * 60 * 1000);
        },
        formatDate(date, format = "mm/dd/yyyy") {
            date = new Date(date);
            if (format === "dd/mm/yyyy") {
                return [
                    this.padTo2Digits(date.getDate()),
                    this.padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join("/");
            }
            if (format === "dd/mm/yyyy H:i") {
                return [
                    this.padTo2Digits(date.getDate()),
                    this.padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join("/") + " " + 
                [
                    this.padTo2Digits(date.getHours()),
                    this.padTo2Digits(date.getMinutes())
                ].join(":");
            }
            if (format === "yyyy/mm/dd") {
                return [
                    date.getFullYear(),
                    this.padTo2Digits(date.getMonth() + 1),
                    this.padTo2Digits(date.getDate()),
                ].join("/");
            }
            if (format === "yyyy-mm-dd") {
                return [
                    date.getFullYear(),
                    this.padTo2Digits(date.getMonth() + 1),
                    this.padTo2Digits(date.getDate()),
                ].join("-");
            }
            if (format === "dd-mm-yyyy") {
                return [
                    this.padTo2Digits(date.getDate()),
                    this.padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join("-");
            }
            console.log("datetobeformatted", date, format);
            if (format === "dd/mm/yyyy") {
                console.log("datetobeformatted", date, format);

                return [
                    this.padTo2Digits(date.getDate()),
                    this.padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join("/");
            }
        },

        formatTime(dateTimeSting){
            let date = new Date(dateTimeSting)
            return [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
            ].join(":");
        },

        formatDateTime(dateTimeString, format = "dd/mm/yyyy hh:mm") {
            let date = new Date(dateTimeString);
            // format date using second argument without 3rd party function
            let dateFormatted = format.split(" ")[0];

            return this.formatDate(date, dateFormatted) + " " + this.formatTime(date, format.split(" ")[1]);
        },
        getMonthAndYearInText(date) {
            date = new Date(date);
            return this.$t('months.long.' + date.getMonth()) + " " + date.getFullYear();
        },
        getWeekNumber(date) {
            date = new Date(date);
            let oneJan = new Date(date.getFullYear(), 0, 1);
            return Math.ceil(
                ((date - oneJan) / 86400000 + oneJan.getDay() + 1) / 7
            );
        },
        /**
         * It handles the type of values that can be entered in the text field based upon its datatypes
         * This is also responsible for the behaviour like : when max length reached no more data can be entered.
         */
        handleInput: function (event, dataType, maxlength, decimalPlaces) {
            event = event ? event : window.event;
            var charCode = event.which ? event.which : event.keyCode;
            if (
                charCode == 9 ||
                charCode == 8 ||
                charCode == 46 ||
                charCode === 16 || 
                charCode === 13 || // Shift Mac
                charCode === 17 || // Control Mac
                charCode === 18 || // Option Mac
                charCode === 91 || // Command Mac
                charCode === 93 || // Command Mac
                (charCode >= 37 && charCode <= 40) ||
                (charCode >= 48 && charCode <= 58)
            ) {
                return true;
            }

            if (
                maxlength != null &&
                (event.target.value + "").replace(".", "").length >=
                Number(maxlength) + Number(decimalPlaces ?? 0)
            ) {
                if (event.target.selectionStart == event.target.selectionEnd) {
                    event.preventDefault();
                } else {
                    if (event.target.selectionEnd == maxlength) {
                        // Allow the user to delete the selected text and press the key typed
                        return true;
                    } else {
                        event.preventDefault();
                    }
                }
            }

            if (dataType === "int") {

                // Do not allow space as well
                if (charCode === 8 || charCode === 16 || charCode === 13 || charCode === 86 ||  /^[0-9]$/i.test(event.key)) {
                    return true;
                } else {
                    return event.preventDefault();
                }
            }

            if (dataType == "float") {
                let dotExists = false;
                if ((event.target.value + "").indexOf(".") != -1) {
                    dotExists = true;
                }
                if (
                    !event.shiftKey &&
                    ((charCode == 190 && !dotExists) ||
                        charCode == 8 || 
                        charCode === 16 || 
                        charCode === 13 ||
                        /^[0-9.,]$/i.test(event.key))
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }

            if (dataType == "alpha") {
                if (charCode == 8 || charCode == 32 ||  charCode === 16 || charCode === 13 ||  /^[a-zA-Z]$/i.test(event.key)) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }

            if (dataType == "email") {
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[a-zA-Z0-9@+-/_]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }

            if (dataType == "alphanumeric") {
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }

            if (dataType == "alphanumericwithenter") {
                console.log(
                    charCode,
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode == 13 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key)
                );
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode == 13 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }
            if (dataType == "everything") {
                // console.log(charCode, charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ));
                // if ( charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ) ) {
                // return true;
                // } else {
                // event.preventDefault();
                // }
            }

            if (dataType == "justalphanumeric") {
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9a-zA-Z]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }
            if (dataType == "justalphanumericwithoutspace") {
                if (charCode == 8 ||  charCode === 16 || charCode === 13 || /^[0-9a-zA-Z]$/i.test(event.key)) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }

            if (dataType == "alphanumdash") {
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9a-zA-Z/-]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }
            if (dataType == "alphanumdashdot") {
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9a-zA-Z.-]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }
            if (dataType == "phonenumber") {
                if (
                    charCode == 8 ||
                    charCode == 32 ||
                    charCode === 16 || 
                    charCode === 13 ||
                    /^[0-9+ /)/(/-]$/i.test(event.key)
                ) {
                    return true;
                } else {
                    event.preventDefault();
                }
            }

            return true;
        },
        handleUnkownInputs: function (event) {
            console.log(event, "handle inputs");
        },
        checkBrowser: function () {
            if (
                navigator.userAgent.indexOf("Firefox") > -1 ||
                typeof InstallTrigger !== "undefined"
            ) {
                return "firefox";
            }
            if (
                navigator.userAgent.indexOf("Safari") > -1 ||
                /constructor/i.test(window.HTMLElement) ||
                (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(
                    !window["safari"] ||
                    (typeof safari !== "undefined" && window["safari"].pushNotification)
                )
            ) {
                return "safari";
            }

            if (
                navigator.userAgent.indexOf("MSIE") > -1 ||
                navigator.userAgent.indexOf("rv:") > -1 ||
                /*@cc_on!@*/ false ||
                !!document.documentMode
            ) {
                return "IE";
            }

            if (
                !(/*@cc_on!@*/ (false || !!document.documentMode)) &&
                !!window.StyleMedia
            ) {
                return "edge";
            }

            if (
                navigator.userAgent.indexOf("Chrome") > -1 ||
                (!!window.chrome &&
                    (!!window.chrome.webstore || !!window.chrome.runtime))
            ) {
                return "chrome";
            }
        },
        autocompleteValue: function () {
            let browser = this.checkBrowser();
            console.log("browser is" + browser, browser == "firefox", "browser ");
            if (browser == "firefox") {
                return "off";
            }
            if (browser == "chrome") {
                return "offf";
            }
            return "offf";
        },

        encryptData(pvtData) {
            pvtData = pvtData + "";
            let secret = this.getCurrentDate();
            return CryptoJS.AES.encrypt(pvtData, secret).toString();
        },

        decryptData(secretData) {
            let secret = API_BASE_URL + this.getCurrentDate();
            return CryptoJS.AES.decrypt(secretData, secret).toString(
                CryptoJS.enc.Utf8
            );
        },
        getCurrentDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + "/" + dd + "/" + yyyy;
            return today;
        },
        getEverythingFamilyArticle() {
            return {
                id: 0,
                parent_family_id: null,
                company_id: null,
                tva_id: 1,
                name: "everything",
                created_at: "2021-03-10",
                updated_at: "2021-03-10",
                migration_id: null,
                properties: [],
            };
        },
        updateFamilyArticleInLocalStorage(familyArticleId) {
            var articleFilters = JSON.parse(
                localStorage.getItem("ARTICLE_MODEL_FILTERS")
            );
            articleFilters["family"] = familyArticleId;
            localStorage.setItem(
                "ARTICLE_MODEL_FILTERS",
                JSON.stringify(articleFilters)
            );
        },
        returnErrorMessage(...args) {
            for (let index = 0; index < args.length; index++) {
                if (args[index] instanceof Array && args[index][0] == true) {
                    return args[index][1];
                }
            }
        },
        generateRandomString(length) {
            var result = "";
            var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            return result;
        },
        filterProperties(customFilters, properties) {
            var filteredProperties = [];
            filteredProperties = customFilters.filter((property) => {
                let found = false;
                properties.forEach((element) => {
                    if (element == property.property_name) {
                        found = true;
                    }
                });
                return found ? property : false;
            });
            return filteredProperties;
        },

        filterPropertiesExceptProperties(customFilters, propertiesToExcept) {
            var filteredProperties = [];
            filteredProperties = customFilters.filter((property) => {
                let found = false;
                propertiesToExcept.forEach((element) => {
                    if (element == property.property_name) {
                        found = true;
                    }
                });

                return found ? false : property;
            });
            return filteredProperties;
        },

        getCompanyPropertyFromLocalStorage(
            propertyNameToFind,
            valuetomatch = null
        ) {
            let propertyValue = "";
            let companyProperties = JSON.parse(
                localStorage.getItem("company_properties")
            );
            let foundValue = null;
            if (companyProperties.length > 0) {
                companyProperties.forEach((companyProperty) => {
                    if (
                        companyProperty.company_setting_key.toLowerCase() ==
                        propertyNameToFind.toLowerCase(companyProperty.company_setting_key)
                    ) {
                        if (valuetomatch) {
                            if (companyProperty.value == valuetomatch)
                                foundValue = companyProperty;
                        } else {
                            foundValue = companyProperty;
                        }
                    }
                });
                return foundValue;
            }
            return null;
        },
        getDecimalNumber() {
            let decimal_precision = this.getCompanyPropertyFromLocalStorage(
                "decimal_precision"
            );
            return decimal_precision ? decimal_precision.value : 2;
        },
        fixDecimal(number, n) {
            if (n == undefined) {
                n = this.getDecimalNumber();
            }
            return (number ? parseFloat(number) : 0).toFixed(n);
        },
        getDatePartFromDateTimeStr(dtstr) {
            if (dtstr) {
                return dtstr.substr(0, 10);
            }
            return "";
        },
        makeZeros(decimalQuantity) {
            if (decimalQuantity && decimalQuantity > 0) {
                let zerostring = "0.";
                for (let count = 0; count < decimalQuantity; count++) {
                    zerostring += "0";
                }
                return zerostring;
            }
            return "0";
        },
        convertToBoolean(val) {
            return val != 0 ? true : false;
        },
        getKeyFromCode(dataArray, code) {
            for (let index = 0; index < dataArray.length; index++) {
                if (dataArray[index].id == code) {
                    return dataArray[index].key;
                }
            }
            return null;
        },
        degreesToRadians(degrees) {
            return degrees * Math.PI / 180;
        },
        distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
            var earthRadiusKm = 6371;
        
            var dLat = this.degreesToRadians(lat2-lat1);
            var dLon = this.degreesToRadians(lon2-lon1);
        
            lat1 = this.degreesToRadians(lat1);
            lat2 = this.degreesToRadians(lat2);
        
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            return earthRadiusKm * c;
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        },
        translateKeys(data, field, section) {
            if (typeof data == "object") {
                for (let index in data) {
                    try {
                        data[index].text = this.$t(section + "." + data[index][field]);
                    } catch (e) {
                        data[index].text = data[index][field];
                    }
                }
                return data;
            } else {
                return this.$t(section + "." + data);
            }
        },
        getPaymentPayedMethods(paymentMethods) {
            let payedMethodsCount = {};
            let payedMethods = "";
            
            console.log("payment methods", paymentMethods);
        
            // Count occurrences of each payment method
            paymentMethods.forEach(element => {
                let key = element.payed_by.key;
                if (payedMethodsCount[key]) {
                    payedMethodsCount[key]++;
                } else {
                    payedMethodsCount[key] = 1;
                }
            });
        
            // Construct the result string
            for (let key in payedMethodsCount) {
                let methodName = this.$t('billings.payment_methods.' + key);
                let count = payedMethodsCount[key];
                if (count > 1) {
                    payedMethods += `${methodName} (${count}), `;
                } else {
                    payedMethods += `${methodName}, `;
                }
            }
        
            return payedMethods.slice(0, -2);
        },        
        encryptToMD5(data) {
            return CryptoJS.MD5(data);
        },
        resetBillType() {
            // this.billTypes.forEach((billType) => {
            //   if (billType.key == "purchase_order") {
            //     this.$store.commit("setBillType", billType);
            //   }
            // });
            let selectedBillType = this.selectDefaultBillTypeFromNavigationData();
            this.$store.commit("setBillType", selectedBillType);
        },
        resetExpensesType() {
            let selectedExpensesType = this.selectDefaultExpenseTypeFromNavigationData();
            this.$store.commit("setExpensesType", selectedExpensesType);
        },
        abortController() {
            // Get the map of abort controllers from the store
            let abortControllerMap = this.$store.state.abortControllerMap;
        
            // If the map is not empty, abort all previous requests
            if (JSON.stringify(abortControllerMap) != "{}") {
                for (let key in abortControllerMap) {
                    abortControllerMap[key].abort();
                }
                // Clear the map after aborting all previous requests
                this.$store.state.abortControllerMap = {};
            }
        
            // Create a new abort controller for the latest request
            let controller = new AbortController();
            let randKey = Math.random().toString(36).substring(7);
        
            // Store the new controller in the map
            this.$store.state.abortControllerMap[randKey] = controller;
        
            // Return the signal of the new abort controller
            return controller.signal;
        },        
        filterFirstError(errors) {
            if (errors === undefined) {
                return null
            }

            for (let key in errors) {
                return errors[key][0]
            }
        },
        debounce(event, callback, delay = 1000) {
            event = event ? event : window.event;
            clearTimeout(this.debounceTimer);
            let value = event.target.value;
            this.debounceTimer = setTimeout(() => {
                let updatedValue = event.target.value;
                if (value === updatedValue) {
                    callback();
                }
            }, delay);
        },
    },
};
